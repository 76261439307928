import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { RouteNames, privateRoutes, publicRoutes } from "./index";
import { useTypeSelector } from "../hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { AuthActionCreators } from "../store/reducers/auth/action-creator";
import { IUser } from "../models/IUser";

const AppRouter = () => {
  const { isAuth } = useTypeSelector((state) => state.authReducer);
  const isAuthenticated = !!localStorage.getItem("account");
  const navigate = useNavigate();
  const [initialRoute, setInitialRoute] = useState<string | null>(null);

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     const storedUser = JSON.parse(
  //       localStorage.getItem("account") || "{}"
  //     ) as IUser;
  //     dispatch(AuthActionCreators.setUser(storedUser));
  //     navigate(RouteNames.ACCOUNT);
  //   } else {
  //     navigate(RouteNames.MEMORIAL);
  //   }
  // }, [isAuthenticated]);

  // useEffect(() => {
  //   const storedRoute = localStorage.getItem("currentRoute");

  //   if (storedRoute) {
  //     setInitialRoute(storedRoute);
  //   } else {
  //     navigate(RouteNames.MEMORIAL);
  //   }
  // }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      localStorage.setItem("currentRoute", window.location.pathname);
    };

    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  return (
    <Routes>
      {isAuthenticated
        ? privateRoutes.map((route) => (
            <Route
              path={route.path}
              element={<route.element />}
              key={route.path}
            />
          ))
        : publicRoutes.map((route) => (
            <Route
              path={route.path}
              element={<route.element />}
              key={route.path}
            />
          ))}
      {/* <Route path={"*"} element={"/"} /> */}
    </Routes>
  );
};

export default AppRouter;
