import React, { FC, useState, useEffect, useCallback } from "react";
import {
  YMaps,
  Map,
  Polygon,
  Polyline,
  TypeSelector,
  Panorama,
  ZoomControl,
  RouteButton,
  Circle
} from "@pbe/react-yandex-maps";

import "./styles.scss";
import {
  hasDoubleNestedArray,
  hasNestedArray,
} from "../UI/functions/functions";

interface MapComponentsProps {
  coordinats: any[]; // Replace 'any' with the specific type of your coordinates
  styleContainer: string;
  mapStyle: string;
  modal: boolean;
  onClose: () => void;
  onChange: () => void;
  landplot: any; // Replace 'any' with the specific type of your landplot
  activeLandploat: any; // Replace 'any' with the specific type of your activeLandploat
  optionsMap: any; // Replace 'any' with the specific type of your optionsMap
}

const MapComponents: FC<MapComponentsProps> = ({
  coordinats,
  styleContainer,
  mapStyle,
  modal,
  onClose,
  onChange,
  landplot,
  activeLandploat,
  optionsMap,
}) => {
  const [placemarks, setPlacemarks] = useState<any[]>([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [drawPolygonCoordinates, setDrawPolygonCoordinates] = useState<any[]>(
    []
  );
  const [selectedPoint, setSelectedPoint] = useState<any>(null);
  const [lines, setLines] = useState<any[]>([]);

  useEffect(() => {
    setPlacemarks(coordinats);
    setDrawPolygonCoordinates([]);
    const newLine = [...coordinats];
    setLines((prevLines) => [...prevLines, newLine]);
  }, [coordinats]);

  const dataCoordCenter =
    activeLandploat !== undefined ? activeLandploat : placemarks;

  // Вычисляем средние значения широты и долготы
  let sumLat = 0;
  let sumLng = 0;

  for (const coords of dataCoordCenter) {
    sumLat += coords[0]; // Широта (latitude)
    sumLng += coords[1]; // Долгота (longitude)
  }

  const center = {
    0: sumLat,
    1: sumLng,
  };



  return (
    <div className={`mapContainer ${styleContainer}`}>
      <YMaps
        query={{
          apikey: "a2718abd-328d-4bd5-a0e1-f246345a30d4",
        }}
      >
        {dataCoordCenter && (
          <Map
            defaultState={{
              //@ts-ignore
              center:
                !isNaN(center[0]) && !isNaN(center[1])
                  ? center
                  // : [50.593776, 36.585727],
                  : [landplot[0], landplot[1]],
              zoom:
                !isNaN(center[0]) && !isNaN(center[1])
                  ? activeLandploat !== undefined
                    ? 19
                    : 18
                  : 18,
            }}
            modules={["geoObject.addon.editor"]}
            className={`mapStyle ${mapStyle}`}
          >
            <TypeSelector
              //@ts-ignore
              options={modal ? { float: "left" } : { float: "right" }}
            />
            <ZoomControl
              options={
                modal
                  ? //@ts-ignore
                  { float: "none", position: { top: 100, left: 20 } }
                  : { float: "none", position: { top: 100, right: 20 } }
              }
            />

            <RouteButton />

            {dataCoordCenter.length !== 0 &&
              (hasDoubleNestedArray(placemarks) ? (
                placemarks.map((coords, index) => (
                  <Polyline
                    geometry={[...coords, coords[0]]}
                    options={{ strokeColor: "#70707B", strokeWidth: 3 }}
                  />
                ))
              ) : (
                <Polyline
                  geometry={[...placemarks, placemarks[0]]}
                  options={{ strokeColor: "#70707B", strokeWidth: 3 }}
                />
              ))}

            {selectedPoint && <Panorama defaultPoint={selectedPoint} />}


            {landplot != null ?
              <>
                <Circle
                  geometry={[[landplot[0], landplot[1]], 3]}
                  options={{
                    draggable: true,
                    fillColor: "#ed0a34",
                    strokeColor: "#ed0a34",
                    strokeOpacity: 0.8,
                    strokeWidth: 1,
                  }}
                />
              </>
              : ''}

          </Map>
        )}
      </YMaps>
    </div>
  );
};

export default MapComponents;
