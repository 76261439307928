import React, { FC } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./styles.scss";
import Buttons from "../../components/Buttons/Buttons";
import icons from "../../assets/icons/icons";
import { useNavigate } from "react-router-dom";
import ServicesComponent from "../../components/ServicesComponent/ServicesComponent";

const ServicesPage: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="containerFull">
      <div className="containerPageSearch">
        <Header />
        <Buttons
          ico={icons.Arrow}
          text="Назад"
          onClick={() => {
            navigate(-1);
          }}
          className="buttonBack"
        />
        <ServicesComponent />
      </div>

      <Footer />
    </div>
  );
};

export default ServicesPage;
