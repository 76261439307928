import React, { FC, useEffect, useState } from "react";
import Buttons from "../Buttons/Buttons";
import icons from "../../assets/icons/icons";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import ServicesApiRequest from "../../api/Services/Services";

interface IServices {
  name: string;
  description: string;
  id: number | string;
  price: string | number;
}

const ServicesComponent: FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem("account");
  const servicesApi = new ServicesApiRequest();
  const [isServices, setServices] = useState<IServices[]>([]);
  const dataServices = [
    {
      id: 1,
      title: "Возложим цветы: гвоздики, розы и хризантемы ",
      icon: icons.flower,
    },
    {
      id: 2,
      title: "Уберем сорняки и мусор, помоем памятник",
      icon: icons.Cleaning,
    },
    {
      id: 3,
      title: "Покрасим оградку, зачистим ее от старой краски и ржавчины",
      icon: icons.Design,
    },
  ];

  useEffect(() => {
    servicesApi.list().then((resp) => {
      if (resp.success) {
        setServices(
          //@ts-ignore
          resp?.data?.results
        );
      }
    });
  }, []);

  return (
    <div className="containerServices" id="services">
      <h1 className="servicesTitle">Заказ услуги по уходу за захоронениями</h1>
      <h2 className="servicesSubTitle">
        Выберите необходимую услугу, укажите предполагаемый бюджет и сроки
        Полный список услуг доступен в карточке заказа
      </h2>

      <div className="conteinerCardsRow">
        {isServices.map((item) => (
          <div key={item.id} className="containerCard">
            <h3>{item.name}</h3>
            <h5>{item.description}</h5>
            {/* <Buttons
              text={"Заказать"}
              onClick={() => {}}
              ico={item.icon}
              className="searchButton"
            /> */}
          </div>
        ))}
      </div>
      {/* <Buttons
        text="Личный кабинет"
        className="buttonServices"
        onClick={() => {
          isAuthenticated
            ? navigate(`${RouteNames.ACCOUNT}`)
            : navigate(`${RouteNames.LOGIN}`);
        }}
      /> */}
    </div>
  );
};

export default ServicesComponent;
