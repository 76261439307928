import React from "react";
import MemorialPage from "../pages/MemorialPage/MemorialPage";
import SearchCardPage from "../pages/SearchCardPage/SearchCardPage";
import DeceasedPage from "../pages/DeceasedPage/DeceasedPage";
import Login from "../pages/Login/Login";
import PersonalAccountPage from "../pages/PersonalAccountPage/PersonalAccountPage";
import HelpPage from "../pages/HelpPage/HelpPage";
import UserAgreement from "../pages/UserAgreement/UserAgreement";
import Requisites from "../pages/Requisites/Requisites";
import ServicesPage from "../pages/ServicesPage/ServicesPage";

export interface IRoute {
    path: string;
    element : React.ComponentType;
    exact?: boolean;
    id?: string;
}

export enum RouteNames {
  
    SEARCH = '/search',
    DECEASED = '/deceased',
    MEMORIAL = '/',
    // LOGIN = '/login',
    // ACCOUNT = '/account',
    HELP = '/help',
    SERVICESPAGE = '/services',
    USERAGREEMENT = '/userAgreement',
    REQUISITES = '/requisites',

}

export const publicRoutes: IRoute[] = [
    // {path: RouteNames.LOGIN, exact: false, element: Login},
    {path: RouteNames.MEMORIAL, exact: false, element: MemorialPage},
    { path: `${RouteNames.DECEASED}/:id`, exact: true, element: DeceasedPage },
    {path: RouteNames.SEARCH, exact: false, element: SearchCardPage},
    {path: RouteNames.HELP, exact: false, element: HelpPage},
    {path: RouteNames.SERVICESPAGE, exact: false, element: ServicesPage},
    {path: RouteNames.USERAGREEMENT, exact: false, element: UserAgreement},
    {path: RouteNames.REQUISITES, exact: false, element: Requisites},

]

export const privateRoutes: IRoute[] = [
    // {path: RouteNames.ACCOUNT, exact: false, element: PersonalAccountPage},
    // {path: RouteNames.LOGIN, exact: false, element: Login},
    {path: RouteNames.MEMORIAL, exact: false, element: MemorialPage},
    { path: `${RouteNames.DECEASED}/:id`, exact: true, element: DeceasedPage },
    {path: RouteNames.SEARCH, exact: false, element: SearchCardPage},
    {path: RouteNames.SERVICESPAGE, exact: false, element: ServicesPage},
    {path: RouteNames.HELP, exact: false, element: HelpPage},
    {path: RouteNames.USERAGREEMENT, exact: false, element: UserAgreement},
    {path: RouteNames.REQUISITES, exact: false, element: Requisites},
]