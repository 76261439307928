import React, { FC } from "react";
import icons from "../../assets/icons/icons";
import "./styles.scss";
import { useDispatch } from "react-redux";
import Buttons from "../Buttons/Buttons";
import { useNavigate, useParams } from "react-router-dom";
import { RouteNames } from "../../routes";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import { AuthActionCreators } from "../../store/reducers/auth/action-creator";
import { useState } from "react";
import { useEffect } from "react";

const Header: FC = () => {
  const dispatch = useDispatch();
  // const { user } = useTypeSelector((state) => state.authReducer);
  const storedUser = localStorage.getItem("account");
  const user = storedUser ? JSON.parse(storedUser) : null;
  const navigate = useNavigate();

  const { isAuth } = useTypeSelector((state) => state.authReducer);
  const isAuthenticated = !!localStorage.getItem("account");

  const urlParams = window.location.pathname.split("/");

  const dataNav = [
    // {
    //   id: 1,
    //   label: "Поиск захоронений",
    //   route: "",
    // },
    // {
    //   id: 2,
    //   label: "Услуги",
    //   route: RouteNames.SERVICESPAGE,
    // },
    // {
    //   id: 3,
    //   label: "Помощь",
    //   route: RouteNames.HELP,
    // },
  ];

  const [isMobile, setIsMobile] = useState(false);
  const [isOpenBurger, setIsOpenBurger] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 768); // Задайте нужное вам значение для ширины мобильного экрана
    };

    // Вызывайте handleResize при загрузке страницы и при изменении размеров окна
    window.addEventListener("resize", handleResize);
    handleResize(); // Вызовите при монтировании компонента

    // Очистите слушателя событий при размонтировании компонента
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logOut = () => {
    //@ts-ignore
    dispatch(AuthActionCreators.logout());
    navigate(RouteNames.MEMORIAL);
  };

  return (
    <header className={`header ${isMobile && "mobile"}`}>
      <div className="containerHeader">
        <div
          className="headerLogoText"
          onClick={() => {
            navigate(`/`);
          }}
        >
          <img src={isOpenBurger ? icons.logoWhiteHeader : icons.Logo} />
          <div className={`headerLogoTitle ${isOpenBurger && "active"}`}>
            <h1>Ритуальный сервис г. Белгорода</h1>
            <p>Городской ритуальный сервис</p>
          </div>
        </div>
        {/* {isMobile && (
          <div
            onClick={() => setIsOpenBurger(!isOpenBurger)}
            className={`burgerMenu ${isOpenBurger && "active"}`}
          ></div>
        )} */}

        <nav className={`userHeaderContainer ${isOpenBurger && "active"}`}>
          {/* {dataNav.map((item) => {
            return <a onClick={() => navigate(item.route)}>{item.label}</a>;
          })} */}
          {/* 
          {urlParams[1] === "account" ? (
            isAuthenticated ? (
              <Buttons
                text="Выход"
                onClick={() => {
                  logOut();
                }}
              />
            ) : (
              <Buttons
                text="Личный кабинет"
                onClick={() => {
                  isAuthenticated
                    ? navigate(`${RouteNames.ACCOUNT}`)
                    : navigate(`${RouteNames.LOGIN}`);
                }}
              />
            )
          ) : isAuthenticated ? (
            <Buttons
              text={`${user?.last_name ? user?.last_name : ""} 
              ${user?.first_name ? user?.first_name?.slice(0, 1) : ""}. ${
                user?.patronymic ? user?.patronymic?.slice(0, 1) : ""
              }.`}
              onClick={() => {
                isAuthenticated
                  ? navigate(`${RouteNames.ACCOUNT}`)
                  : navigate(`${RouteNames.LOGIN}`);
              }}
            />
          ) : (
            <Buttons
              text="Личный кабинет"
              onClick={() => {
                isAuthenticated
                  ? navigate(`${RouteNames.ACCOUNT}`)
                  : navigate(`${RouteNames.LOGIN}`);
              }}
            />
          )} */}
        </nav>
      </div>
    </header>
  );
};

export default Header;
