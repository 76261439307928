import React, { FC } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./styles.scss";
import Buttons from "../../components/Buttons/Buttons";
import icons from "../../assets/icons/icons";
import { useNavigate } from "react-router-dom";

const UserAgreement: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="containerFull">
      <div className="containerPageSearch">
        <Header />
        <div className="textStatic">
          <Buttons
            ico={icons.Arrow}
            text="Назад"
            onClick={() => {
              navigate(-1);
            }}
            className="buttonBack"
          />
          <h1>Пользовательское соглашение</h1>
          <p>
            Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует
            отношения между Администрацией Регионального ритуального сервиса
            Белгородской области (Далее – Администрация портала), с одной
            стороны, и пользователем Регионального ритуального сервиса
            Белгородской области (Далее – Пользователь), с другой стороны.
            Региональный ритуальный сервис Белгородской области (далее –
            ритуальный портал) не является средством массовой информации.
          </p>
          <p>
            Используя ритуальный портал, Вы соглашаетесь с условиями данного
            соглашения. Если Вы не согласны с условиями данного соглашения, то
            не используйте ритуальный портал. Администрация портала не является
            исполнителем услуг и не несет ответственности за их качество.
            Предмет соглашения Администрация портала предоставляет пользователю
            право на размещение на ритуальном портале следующей информации:
            Текстовой информации; Фотоматериалов.
          </p>
          <p>
            Права и обязанности сторон Пользователь имеет право: осуществлять
            поиск информации на ритуальном портале получать информацию на
            ритуальном портале создавать информацию для ритуального портала
            копировать информацию на другие сайты с указанием источника
            использовать информацию ритуального портала в личных некоммерческих
            целях Администрация портала имеет право: по своему усмотрению и
            необходимости создавать, изменять, отменять правила; ограничивать
            доступ к любой информации на ритуальном портале; создавать,
            изменять, удалять информацию; удалять учетные записи; отказывать в
            регистрации без объяснения причин. Пользователь обязуется: не
            нарушать работоспособность ритуального портала; не создавать
            несколько учётных записей на ритуальном портале, если фактически они
            принадлежат одному и тому же лицу; не совершать действия,
            направленные на введение других пользователей в заблуждение; не
            передавать в пользование свою учетную запись и/или логин и пароль
            своей учетной записи третьим лицам; не регистрировать учетную запись
            от имени или вместо другого лица за исключением случаев,
            предусмотренных законодательством РФ; не размещать материалы
            рекламного, эротического, порнографического или оскорбительного
            характера, а также иную информацию, размещение которой запрещено или
            противоречит нормам действующего законодательства РФ; не
            использовать скрипты (программы) для автоматизированного сбора
            информации и/или взаимодействия с ритуальным порталом и его
            сервисами. Администрация обязуется: поддерживать работоспособность
            ритуального портала за исключением случаев, когда это невозможно по
            независящим от Администрации портала причинам. Ответственность
            сторон: Пользователь лично несет полную ответственность за
            распространяемую им информацию; Администрация портала не несет
            никакой ответственности за достоверность информации, скопированной
            из других источников; Администрация портала не несёт ответственность
            за несовпадение ожидаемых Пользователем и реально полученных услуг;
            Администрация портала не несет никакой ответственности за услуги,
            предоставляемые третьими лицами; в случае возникновения
            форс-мажорной ситуации (боевые действия, чрезвычайное положение,
            стихийное бедствие и т. д.)
          </p>
          <p>
            Администрация портала не гарантирует сохранность информации,
            размещённой Пользователем, а также бесперебойную работу
            информационного ресурса Администрация портала не является участником
            сделок между Пользователями (Заказчиками работ и услуг) и
            Исполнителями. Администрация портала не гарантирует качество,
            безопасность и законность работ и услуг Исполнителей, уровень
            квалификации Исполнителя для выполнения каких-либо работ и услуг,
            оплату Заказчиком работы или услуги Исполнителя, а также не
            гарантирует, что Исполнитель может и в действительности будет вести
            работы или услуги по предложенной заявке. Условия действия
            Соглашения Данное Соглашение вступает в силу при любом использовании
            данного ритуального портала. Соглашение перестает действовать при
            появлении его новой версии. Администрация портала оставляет за собой
            право в одностороннем порядке изменять данное Соглашение по своему
            усмотрению. Администрация портала не оповещает пользователей об
            изменениях в Соглашении.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default UserAgreement;
