import React, { FC } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Buttons from "../../components/Buttons/Buttons";
import icons from "../../assets/icons/icons";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

const Requisites: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="containerFull">
      <div className="containerPageSearch">
        <Header />
        <div className="textStatic">
          <Buttons
            ico={icons.Arrow}
            text="Назад"
            onClick={() => {
              navigate(-1);
            }}
            className="buttonBack"
          />
          <table>
            <tr>
              <th>Наименование</th>
              <td>МБУ «УПРАВЛЕНИЕ БЕЛГОРБЛАГОУСТРОЙСТВО»</td>
            </tr>
            <tr>
              <th>Сокращенное наименование</th>
              <td>МБУ «УБГБ»</td>
            </tr>
            <tr>
              <th>Юридический адрес</th>
              <td>
                Белгородская обл., г. Белгород, ул. Николая Чумичова, д. 31 к. А
              </td>
            </tr>
            <tr>
              <th>Почтовый адрес</th>
              <td>
                Белгородская обл., г. Белгород, ул. Николая Чумичова, д. 31 к. А
              </td>
            </tr>
            <tr>
              <th>Телефон/факс</th>
              <td>8 (4722) 27-59-95</td>
            </tr>
            <tr>
              <th>ИНН/КПП</th>
              <td>3123073734/312301001</td>
            </tr>
            <tr>
              <th>ОГРН</th>
              <td>1023101671614</td>
            </tr>
            <tr>
              <th>Расчётный счет</th>
              <td>03234643147010002600</td>
            </tr>
            <tr>
              <th>Корреспондентский счет</th>
              <td>40102810745370000018</td>
            </tr>
            <tr>
              <th>БИК банка</th>
              <td>011403102</td>
            </tr>
            <tr>
              <th>Банк</th>
              <td>
                ОТДЕЛЕНИЕ БЕЛГОРОД//УФК по Белгородской области г. Белгород
              </td>
            </tr>
            <tr>
              <th>Система налогообложения</th>
              <td>ОСНО</td>
            </tr>
            <tr>
              <th>Классификаторы в статистическом регистре</th>
              <td>
                ОКПО 54670837;
                <br />
                ОКАТО 14401370000;
                <br />
                ОКТМО 37000000;
                <br />
                ОКОГУ 421007;
                <br />
                ОКФС 14;
                <br />
                ОКОПФ 75403;
                <br />
                ОКВЭД 81.29.9
              </td>
            </tr>
            <tr>
              <th>Генеральный директор</th>
              <td>Жигалов Евгений Владимирович</td>
            </tr>
            <tr>
              <th>Действует на основании</th>
              <td>Устав</td>
            </tr>
          </table>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Requisites;
