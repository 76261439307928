import React, { FC } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./styles.scss";
import Buttons from "../../components/Buttons/Buttons";
import icons from "../../assets/icons/icons";
import { useNavigate } from "react-router-dom";

const HelpPage: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="containerFull">
      <div className="containerPageSearch">
        <Header />
        <div className="textStatic">
          <Buttons
            ico={icons.Arrow}
            text="Назад"
            onClick={() => {
              navigate(-1);
            }}
            className="buttonBack"
          />
          <h1> Для чего предназначен портал?</h1>
          <p>
            Сохранять традиции, чтить память о близких и содержать кладбища в
            чистоте – это и есть основная цель создания портала. И сейчас
            современные технологии не заменяют, а помогают сохранить наши
            общечеловеческие ценности. Именно для этого разработан наш сервис.
          </p>
          <p>
            Мы помогаем населению найти захоронения родных и близких,
            централизованно и удобно хранить информацию, делиться данными с
            родственниками, заказать услуги по уходу за захоронениями, напомнить
            о важных датах, разместить биографию, семейные истории и сохранить
            достижения человека для потомков. Заботы по благоустройству мест
            захоронений очень важны, особенно в преддверии праздников. Но
            обстоятельства иногда складываются так, что на это просто нет ни,
            времени ни сил. Вы всегда можете доверить уход за могилой
            профессионалам, заказав необходимую услугу на нашем сайте. Получив
            фотоотчет исполнителя, Вы заранее будете знать, что все убрано и
            красиво, и, приехав на кладбище, проведете время в уединении, а не в
            заботах.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HelpPage;
