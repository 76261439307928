import BaseModelAPI from "../BaseModelAPI";
import apiConfig from "../apiConfig";
import axiosClient from "../axiosClient";
import { API_DECEASED_MODEL } from "./const";

class DecausedApiRequest extends BaseModelAPI {
    constructor() {
        super(API_DECEASED_MODEL.url, apiConfig.baseUrl);
    }

    async search(id?: string | undefined, urlParams?: string|undefined) {
        return this.makeRequest(axiosClient.get, { method: API_DECEASED_MODEL.methods.search.url, id: id, urlParams: urlParams });
    }
    async searchGetById(id?: string | undefined, urlParams?: string|undefined) {
        return this.makeRequest(axiosClient.get, { method: API_DECEASED_MODEL.methods.search.url + id});
    }
    async searchOption() {
        return this.makeRequest(axiosClient.options, {method: API_DECEASED_MODEL.methods.search.url});
    }
    async favourites() {
        return this.makeRequest(axiosClient.get, {method: API_DECEASED_MODEL.methods.favourites.url});
    }
    async addFavourite(id:string | number | undefined) {
        return this.makeRequest(axiosClient.post, {method:  API_DECEASED_MODEL.methods.favourites.url + id + '/' + API_DECEASED_MODEL.methods.addFavourite.url});
    }
}

export default DecausedApiRequest;