import React, { FC } from "react";
import icons from "../../assets/icons/icons";
import "./styles.scss";
import { RouteNames } from "../../routes";
import { useNavigate } from "react-router-dom";

const Footer: FC = () => {
  const navigation = useNavigate();
  return (
    <header className="footer">
      <div className="containerFooter">
        <div className="footerLogoText">
          <div className="footerLogo">
            <img src={icons.LogoWhite} />
            <p>Городской ритуальный сервис</p>
          </div>
          <a onClick={() => navigation(RouteNames.REQUISITES)}>Реквизиты</a>
          <a onClick={() => navigation(RouteNames.USERAGREEMENT)}>
            Пользовательское соглашение
          </a>
          <a href="mailto:M31Belgorod@yandex.ru">M31Belgorod@yandex.ru</a>
        </div>
      </div>
    </header>
  );
};

export default Footer;
