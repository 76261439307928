import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IDeceased } from "../../models/IDeceased";
import "./styles.scss";
import { fieldToArray, getFormatedDate } from "../UI/functions/functions";
import Buttons from "../Buttons/Buttons";
import icons from "../../assets/icons/icons";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../routes";
import Pagination from "../Pagination/Pagination";
import { DeceasedActionCreators } from "../../store/reducers/deceased/action-creatorDeceased";
import DecausedApiRequest from "../../api/Decaused/Decaused";

const ContainerSearchCard: FC = () => {
  const decausedDataApi = new DecausedApiRequest();
  const decausedData = useSelector(
    (state: any) => state.deceasedReducer.deceased
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataPress = useSelector(
    (state: any) => state.dataPressReducer.dataPress
  );

  const [stringSearch, setStringSearch] = useState<string>("");
  const handleUpdatePage = (number: string | number) => {
    console.log("");

    const queryParams = fieldToArray(dataPress)
      .map((item) => `${item.key}=${item.value}`)
      .join("&");
    const urlParams = `?${queryParams}&page=${number}`;

    decausedDataApi.search("", urlParams).then((resp) => {
      if (resp.success) {
        dispatch(
          //@ts-ignore
          DeceasedActionCreators.setDeceased(resp?.data && resp?.data)
        );
        // Navigate to the search results page
        navigate(RouteNames.SEARCH);
      }
    });
  };

  return (
    <div className="searchCardContainer">
      <Buttons
        ico={icons.Arrow}
        text="Назад"
        onClick={() => {
          navigate(-1);
        }}
        className="buttonBack"
      />
      <h1 className="decausedTitle">Результат поиска</h1>
      <h5 className="decausedSubTitle">{`Найдено ${decausedData?.count} захоронений`}</h5>
      <div className="containerCardDecaused">
        {decausedData?.results?.map((item: IDeceased) => {
          return (
            <div
              className="cardDecaused"
              key={item.id}
              onClick={() => navigate(`${RouteNames.DECEASED}/${item.id}`)}
            >
              <h3>{`${item.last_name ? item.last_name : "Неизвестно"} ${
                item.first_name ? item.first_name : "Неизвестно"
              } ${item.patronymic ? item.patronymic : "Неизвестно"}`}</h3>
              <span>
                {`${
                  item.birth_date
                    ? getFormatedDate(item.birth_date ? item.birth_date : "")
                    : "Неизвестно"
                }-${
                  item.death_date
                    ? getFormatedDate(item.death_date ? item.death_date : "")
                    : "Неизвестно"
                }`}
              </span>
            </div>
          );
        })}
      </div>
      {decausedData?.results && decausedData?.results.length !== 0 && (
        <Pagination
          respData={decausedData}
          handlePage={(e) => {
            handleUpdatePage(e);
          }}
          handleSearch={(e) => {
            setStringSearch(e);
          }}
        />
      )}
    </div>
  );
};

export default ContainerSearchCard;
