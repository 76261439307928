export const API_DECEASED_MODEL = {
    entity: 'deceased',
    url: 'deceased/',
    methods: {
        search:{
            url: 'search-deceased/'
        },
        favourites:{
            url: 'favourites/'
        },
        addFavourite:{
            url: 'favourite/'
        }
    },
}