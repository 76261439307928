import React, { FC, useEffect, useState } from "react";
import DecausedApiRequest from "../../api/Decaused/Decaused";
import CemeteryApiRequest from "../../api/Cemetery/Cemetery";
import MapComponents from "../MapComponents/MapComponents";
import "./styles.scss";
import Buttons from "../Buttons/Buttons";
import icons from "../../assets/icons/icons";
import { RouteNames } from "../../routes";
import { useNavigate } from "react-router-dom";
import { getFormatedDate } from "../UI/functions/functions";
import { useTypeSelector } from "../../hooks/useTypedSelector";
import apiConfig from "../../api/apiConfig";
import Modal from "../Modal/Modal";
import Slider from "react-slick";
import { Galleria } from "primereact/galleria";

interface DeceasedInfoUserProps {
  id: string | undefined;
}
interface FavouritesInfoDecausedProps {
  id: string | undefined;
}
interface DeceasedData {
  id: number | undefined;
  birth_date: string | undefined;
  images: string | undefined;
  burial_date: string | undefined;
  cemetery_plot: string | undefined;
  death_date: string | undefined;
  first_name: string | undefined;
  last_name: string | undefined;
  patronymic: string | undefined;
  notes: string | undefined;
  favourites: FavouritesInfoDecausedProps;
}
interface CemeteryData {
  id: number | undefined;
  burial: string | undefined;
  cemetery: number | undefined;
  coordinates: [] | undefined;
  deceased: [] | undefined;
  description: string | undefined;
  note: string | undefined;
  place: string | undefined;
  plot_number: string | undefined;
  row: string | undefined;
  sector: string | undefined;
  status: string | undefined;
  type: string | undefined;
}
interface CemeteryCoordData {
  id: number | undefined;
  area: string | undefined;
  coordinates: [] | undefined;
  date_end: string | undefined;
  date_start: string | undefined;
  description: string | undefined;
  municipality: string | undefined;
  name: string | undefined;
  status: string | undefined;
}

const DeceasedInfoUser: FC<DeceasedInfoUserProps> = ({ id }) => {
  const decausedApi = new DecausedApiRequest();
  const cemeteryApi = new CemeteryApiRequest();
  const [deceasedData, setDeceasedData] = useState<DeceasedData>();
  const [cemeteryData, setCemeteryData] = useState<CemeteryData>();
  const userData = useTypeSelector((state) => state.authReducer.user);
  const [isLike, setIsLike] = useState<boolean>(false);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [cemeteryCoordinates, setCemeteryCoordinates] =
    useState<CemeteryCoordData>();
  const navigate = useNavigate();

  const isAuthenticated = !!localStorage.getItem("account");

  useEffect(() => {
    decausedApi.searchGetById(id + "/").then((resp) => {
      console.log(resp);
      if (resp.success) {
        //@ts-ignore
        setDeceasedData(resp.data);

        cemeteryApi
          //@ts-ignore
          .getCemeteryPlots(resp?.data?.cemetery_plot + "/")
          .then((cemetry) => {
            if (cemetry.success) {
              //@ts-ignore
              setCemeteryData(cemetry?.data);

              cemeteryApi
                //@ts-ignore
                .getCemetery(cemetry?.data?.cemetery + "/")
                .then((resp) => {
                  if (resp.success) {
                    //@ts-ignore
                    setCemeteryCoordinates(resp.data);
                  }
                });
            }
          });
      }
    });
  }, []);

  useEffect(() => {
    setIsLike(
      //@ts-ignore
      deceasedData?.favourites && deceasedData.favourites.includes(userData.id)
    );
  }, [deceasedData]);

  // const handleToFavourites = (id: string | undefined) => {
  //   decausedApi.addFavourite(id).then((resp) => {
  //     if (resp.success) {
  //       setIsLike(!isLike);
  //       navigate(RouteNames.ACCOUNT);
  //     }
  //   });
  // };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const itemTemplate = (item: { file: string }) => {
    return (
      <img
        src={`${apiConfig.baseUrlMedia}${item?.file}`}
        className="imgDeceasedModal"
        onClick={(e) => {
          e.currentTarget.classList.toggle("imgDeceasedModal--scaled");
        }}
      />
    );
  };

  return (
    <>
      {isOpenModal && (
        <Modal
          content={
            //@ts-ignore
            cemeteryData?.images &&
            //@ts-ignore
            cemeteryData?.images.length > 0 && (
              <Galleria
                showItemNavigators
                showItemNavigatorsOnHover
                showIndicators
                showThumbnails={false}
                numVisible={5}
                circular
                value={
                  //@ts-ignore
                  cemeteryData?.images
                }
                item={itemTemplate}
              />
            )
          }
          onClose={() => setOpenModal(false)}
        />
      )}
      <div className="deceasedInfo">
        <div className="containerImg">
          <Buttons
            ico={icons.Arrow}
            text="Назад"
            onClick={() => navigate(-1)}
            className="buttonBack"
          />
        </div>
        <div className="containerInfo">
          <div className="containerInfoUser">
            <h1>{`${
              deceasedData?.last_name ? deceasedData.last_name : "Неизвестно"
            } ${
              deceasedData?.first_name ? deceasedData.first_name : "Неизвестно"
            } ${
              deceasedData?.patronymic ? deceasedData.patronymic : "Неизвестно"
            }`}</h1>
            <div className="blockInfoDeceased">
              <img
                src={
                  //@ts-ignore
                  cemeteryData?.images?.some((item) => item.is_preview)
                    ? `${apiConfig.baseUrlMedia}${
                        //@ts-ignore
                        cemeteryData?.images?.find((item) => item.is_preview)
                          ?.file
                      }`
                    : icons.photoNone
                }
                className="imgDeceased"
                onClick={(e) => {
                  setOpenModal(true);
                }}
              />

              <div className="blockGray">
                <div>
                  <h5>Год рождения</h5>
                  <span>
                    {`${
                      deceasedData?.birth_date
                        ? getFormatedDate(
                            deceasedData?.birth_date
                              ? deceasedData?.birth_date
                              : ""
                          )
                        : "Неизвестно"
                    }`}
                  </span>
                  <h5>Год смерти</h5>
                  <span>
                    {`${
                      deceasedData?.death_date
                        ? getFormatedDate(
                            deceasedData?.death_date
                              ? deceasedData?.death_date
                              : ""
                          )
                        : "Неизвестно"
                    }`}
                  </span>
                  <h2>{`Кладбище ${cemeteryCoordinates?.name}`}</h2>
                  {/* <h2>
                    {cemeteryData?.coordinates &&
                      //@ts-ignore
                      `${cemeteryData?.coordinates[0][0][0]},${cemeteryData?.coordinates[0][0][1]}`}
                  </h2> */}
                  <div className="gridCard">
                    <h5>{`Место: ${
                      cemeteryData?.place ? cemeteryData.place : "Неизвестно"
                    }`}</h5>
                    <h5>{`Номер участка: ${
                      cemeteryData?.plot_number
                        ? cemeteryData.plot_number
                        : "Неизвестно"
                    }`}</h5>
                    <h5>{`Ряд: ${
                      cemeteryData?.row ? cemeteryData.row : "Неизвестно"
                    }`}</h5>
                    <h5>{`Сектор: ${
                      cemeteryData?.sector ? cemeteryData.sector : "Неизвестно"
                    }`}</h5>
                  </div>
                </div>
                {/* {
                  <Buttons
                    text={isLike ? "Сохранено" : "Сохранить"}
                    onClick={() => {
                      isAuthenticated
                        ? handleToFavourites(id)
                        : navigate(RouteNames.LOGIN);
                    }}
                    className="saveDeceasedButton"
                  />
                } */}
              </div>
            </div>
          </div>
          {cemeteryCoordinates ? (
            <MapComponents
              //@ts-ignore
              coordinats={
                //@ts-ignore
                cemeteryCoordinates ? cemeteryCoordinates?.coordinates : []
              }
              styleContainer={"styleMapContainer"}
              mapStyle={"mapStyle"}
              modal={false}
              onClose={function (): void {
                throw new Error("Function not implemented.");
              }}
              onChange={function (): void {
                throw new Error("Function not implemented.");
              }}
              landplot={
                cemeteryData?.coordinates ? cemeteryData?.coordinates : []
              }
              activeLandploat={
                cemeteryData?.coordinates ? cemeteryData?.coordinates : []
              }
              optionsMap={undefined}
            />
          ) : (
            <p>Загрузка карты</p>
          )}
        </div>
      </div>
    </>
  );
};

export default DeceasedInfoUser;
